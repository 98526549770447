/* navbar.scss */

.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    // background-color: #FAF6FF; /* Replace with your desired background color */
    color: #000000; /* Replace with your desired text color */
  }
  
  .logo-container {
    display: flex;
    align-items: center;
  
    .logo {
      margin-right: 10px; /* Adjust margin as needed */
    }
  }
  
  .home-svg-container {
    display: flex;
    align-items: center;
    margin-left: 10px; /* Adjust margin as needed */
  }
  
  .user-info-container {
    display: flex;
    align-items: center;
    width: "10%";
  }
  
  .user-info-container p {
    color: #000000;
    
    font-weight: 400;
    font-size: 14px;
  }
  
  .user-info-container img {
    height: 20px;
    width: 20px;
  }
  