.Slider {
    align-items: center;
    display: flex;
    height: 96px;
    /* flex: 1; */
    width: 420px;
  }
  
  .images-container {
    display: flex;
    flex: 1;
    /* height: 150; */
    overflow-x: scroll;
    scroll-behavior: smooth;
    transition: scroll 0.3s ease-in-out;
    align-items: center;
    flex-direction: row;
    gap: 12px;
    position: relative;
  }
  ::-webkit-scrollbar {
    display: none;
}
  
  .image {
    width: 96px;
    height: 96px;
    border-radius: 8px;
    z-index: 0;
    border: '1px solid #BFBFBF';
    display: block;
    object-fit: 'cover';

  }
  
  .nav-btn {
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    background-color: #c3c3c3;
    border-radius: 6px;
    width: 30px;
    margin: 5px;
  }
  