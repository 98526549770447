  .cir {
    width: 296px;
    height: 296px;
    margin: 20px auto;
    border-radius: 50%;
    border: 2px solid transparent;
    align-items: center;
    justify-content: center;
    display: flex;
    background: 
    linear-gradient(#1E1E1E, #005671) padding-box, 
    linear-gradient(to bottom, #FC3CB2, #00C0FB) border-box;
    font-family: 'Poppins';
    color: white;
    font-size: 100px;
    font-weight: 800;
  }
  .circle{
    border:2px solid white;
    border-radius:10000px;
    background:rgba(0,0,0,0);
    color:white;
    width:296px;
    height:296px;
    text-align:center;
    line-height:100px;
    align-items: center;
    justify-content: center;
    display: flex;
    background: -webkit-linear-gradient(left top, crimson 0%, #f90 100%);
  }

  #container {
    width: 800px;
    height: 60px;
    margin: 0 auto;
    position: relative;
    top: 50px;
    transform: translateY(-50%);
    border-radius: 35px;
    overflow: hidden;
  }
  
  .child {
    width: 100%;
    height: 100%;
  }
  
  