.datatable-column {
    border-left: 1.5px solid black !important;

}

.main {
    height: '20%';
    padding: '20px 30px';

}
.scrollbar {
	background-color: #F5F5F5;
	height: 300px;
	overflow-x: scroll;
}

.force-overflow {
}

#style-1::-webkit-scrollbar {
	width: 6px;
	background-color: #F5F5F5;
}
#style-1::-webkit-scrollbar-thumb {
	background-color: #000000;
}
#style-1::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}


  .label {
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 400;
   
    color: #000000;
  }
  
  .gap {
    margin-top: 4px; /* Adjust the gap as needed */
  }
