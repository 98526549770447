@keyframes load {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .loader {
    border: 4px solid rgba(255, 255, 255, 0.2);
    border-left: 4px solid;
    animation: load 1s infinite linear;
    border-radius: 50%;
    width: 18px;
    height: 18px;
  }