.logoContainer{
    display: flex;
    align-items: center;
    .logo{
        display: flex;
        flex-direction: column;
    }

    .logoHeader{
        font-family: 'Poppins';
        font-size: 128px;
        font-weight: 600;
        color: white;
    }
};