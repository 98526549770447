.temp{
    display:flex;
    flex: 1;
    position: sticky;
    align-items: center;
    height: 100vh;
    flex-direction: column;
    .logoContainer{
        display: flex;
        align-items: center;
        .logo{
            display: flex;
            flex-direction: column;
        }
    
        .logoHeader{
            font-family: 'Poppins';font-size: 128px;
            font-weight: 600;
            color: white;
        }
    };
    .buttonContainer{
        padding-top: 30px;
        padding-bottom: 30px;
        flex-direction: column;
        display: flex;
        .uploadButton{
            cursor: pointer;
        }
        .uploadFile{
            display:none;
        }
        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
          }
    };
    

    

}
.modal {
    width: 502px;
    background: #f0f0f0;
    border-radius: 50%;
    min-height: 502px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    padding: 20px;
    top: 30px;
    position: fixed;
    left: 50%;
    margin-left: -250px;
    z-index: 9999;
    height: 100px;
    display: flex; /* or inline-flex */
  align-items: center; 
  justify-content:center;
  background: #003545;
  flex-direction: column;
  text-align: center;

  p{
    font-family: 'Poppins';font-size: 24px;
            color: white
            
  }
  }