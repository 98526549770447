/* sidebar.scss */

.sidebar {
    
    min-height: 10%;
    height: auto;
    background-color: #C6C6C654;
    border-radius: 20px;
    margin-top: 20px;
    box-shadow: 1px 2px 3px #00000040;
    padding: 20px 20px;
    font-family: "Poppins";

    p {
        font-weight: 500;
        font-size: 20px;
    }

    hr {
        height: 1px;
        margin-top: 6px;
        width: 54%;
        border-radius: 10px;
        background-color: #00000052;
    }

    div {
        padding: 10px;

        p {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            margin-top: 6px;

        }
    }





}




.submenu {
    color: black;
    transition-delay: 0.5;
    text-decoration: none;

    &:hover {
        cursor: pointer;
        font-weight: 500;

        transition-delay: 0.5;
    }
}

.submenu-disabled {
    color: #00000080;
    text-decoration: none;

}

.active {
    color: #FC3CB2;
}